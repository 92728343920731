//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from '@/components/Avatar.vue'
import moment from 'moment'
export default {
    name: 'Friend',
    props: [
        'friend'
    ],
    components: {
        Avatar
    },
    data() {
        return {
            friendOption: false,
            confirmFriendRequest: false,
            showSendFriendRequest: true,
            friendPhotoThumbnail: null,
            friendSince: moment(this.friend.date_active).format('LL'),
            isFriend: true
        }
    },
    created(){
        this.loadPhotoThumbnail()
    },
    methods: {
        loadPhotoThumbnail(){
            this.payload = {
                user_id: this.friend.user_id,
            }
            this.$store.dispatch('profile/getProfilePhoto', this.payload)
                .then((response) =>{
                    if(response.status == 'success'){
                        this.friendPhotoThumbnail = response.image
                    }
                })
        },
        viewProfile() {
            this.$router.push({name:"Profile", params: {id: this.friend.user_id}});
        },
        unfriend() {
            this.$http({
                url: 'relations/' + this.friend.user_id + '/unfriend',
                method: 'POST'
            }).then((response) => {
                switch (response.data['status']) {
                    case 'unfriend_successful':
                        this.$q.notify({
                            message: 'You have removed ' + this.friend.first_name + ' from your friend list.',
                            color: 'negative',
                            icon: 'person_remove'
                        });
                        this.isFriend = false;
                        this.$emit('unfriend', this.friend.user_id);
                    break;
                    case 'not_friends':
                        this.$q.notify({
                            message: 'You are not friends with ' + this.friend.first_name + '.',
                            color: 'negative',
                            icon: 'person_remove'
                        })
                        this.$emit('unfriend', this.friend.user_id);
                        this.isFriend = false;
                    break;
                }
            })
        }
    }
}
