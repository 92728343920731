import { render, staticRenderFns } from "./Friend.vue?vue&type=template&id=95c57790&scoped=true&"
import script from "./Friend.vue?vue&type=script&lang=js&"
export * from "./Friend.vue?vue&type=script&lang=js&"
import style0 from "./Friend.vue?vue&type=style&index=0&id=95c57790&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c57790",
  null
  
)

export default component.exports
import {QCard,QCardSection,QItem,QItemSection,QItemLabel,QDialog,QSeparator,QList,QIcon,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QItem,QItemSection,QItemLabel,QDialog,QSeparator,QList,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
