//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Friend from '@/components/User/MyFriends/Friend'
import EmptyMessage from '@/components/EmptyMessage'
export default {
  name: 'MyFriends',
  props: [
    'id',
    'name'
  ],
  data() {
    return {
      search: '',
      isLoading: true,
      currentPage: 1,
      maxNumberOfPages: 1,
      isEmpty: false
    }
  },
  computed:{
    friends (){
      return this.$store.getters['profile/getFriends']
    }
  },
  components: {
    Friend,
    EmptyMessage
  },
  mounted() {
    this.loadFriends();
  },
  methods: {
    loadFriends() {
        this.isLoading = true;
        this.payload = {
          id: this.id,
          page: this.currentPage,
          search: this.search
        }
        this.$store.dispatch('profile/loadFriends',this.payload).then((response) =>{
          if(response.type =='success'){
            if(this.id == this.$store.state.user.id){
              this.$store.commit('user/updateUserFriends',this.$store.state.profile.friends)
            }
            this.isEmpty = false;
            this.maxNumberOfPages = response.maxPages;
            this.currentPage = response.currentPage;
            
          }else{
             if (this.search == "") {
              this.isEmpty = true;
            } else {
              this.$q.notify({
                message: 'No person on this friend list found with that name. Please try a different search query.'
              });
            }
          }
          this.isLoading = false;
        })
    }
  }
}
